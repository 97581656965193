<script lang="ts" setup>
import { ref, computed, onMounted, withDefaults } from 'vue'
import GetTokenByPhone from '@/components/get-token-by-phone.vue'
import EditUserForm from '@/components/edit-user-form.vue'
import { useUserStore } from '@/state/user'
import { useUserMiddleware } from '@/middleware/user-middleware'
import { useBookingMiddleware } from '@/middleware/booking-middleware'
import PlButton from '@/components/pl-button.vue'
import { metrikaGoal } from '@/tools/metrika'
import ShowCreatedBooking from '@/components/show-created-booking.vue'
import FetchAndCancelBooking from '@/components/fetch-and-cancel-booking.vue'
import AddPromo from '@/components/add-promo.vue'
import AddReview from '@/components/add-review.vue'
import type { GiftServiceTopMaster} from '@/static-data/service'
import type { Booking } from '@/static-data/booking'

const props = withDefaults(defineProps<{
  settings?: Record<string, any>
}>(), {
  settings: () => ({})
})

const bookingCreated = ref(false)
const userMiddleware = useUserMiddleware()
const userStore = useUserStore()
const currentStep = ref('get-token-by-phone')
const createBookingDisable = computed(() => !userStore.user.first_name?.length || !userStore.user.last_name?.length || !userStore.user.email?.length)
const bookingMiddleware = useBookingMiddleware()
const isNeedCancelBooking = ref(false)
const booking = ref<Booking | null>(null)
const giftServices = ref<GiftServiceTopMaster[]>([])

const createBooking = async () => {
  try {
    await userMiddleware.updateUser()
    const data = await bookingMiddleware.createBooking()
    booking.value = data.booking
    giftServices.value = data.gift_services
    metrikaGoal('success_lead_form')
    bookingCreated.value = true
    // emits('booking-created', booking.value)
    await bookingMiddleware.sendReferId()
  } catch (e) {
    console.log(e)
    if (e.response?.data?.code === 1430 || e.response?.data?.code === 1433) {
      isNeedCancelBooking.value = true
    }
  }
}

onMounted(() => metrikaGoal('3_step_open'))
</script>

<template>
  <div>
    <div v-if="!bookingCreated && !isNeedCancelBooking">
      <div>
        <add-promo
          v-if="currentStep === 'edit-user-form'"
          class="pl-mt-5"
        />

        <div
          v-if="currentStep === 'edit-user-form'"
          class="pl-bg-gray pl-w-full pl-h-[1px] pl-mt-5"
        ></div>

        <get-token-by-phone
          v-if="currentStep === 'get-token-by-phone'"
          emit-auth-event
          :settings="settings"
          @auth-user="currentStep = 'edit-user-form'"
        />

        <add-review
          v-if="settings.addReview && currentStep === 'edit-user-form'"
        />

        <edit-user-form
          v-if="currentStep === 'edit-user-form'"
        />

        <pl-button
          v-if="currentStep === 'edit-user-form'"
          label="Записаться"
          :disable="createBookingDisable"
          @click="createBooking"
        />
      </div>
    </div>

    <div v-if="bookingCreated">
      <show-created-booking
        :booking="booking"
        :gift-services="giftServices"
      />
    </div>

    <fetch-and-cancel-booking
      v-if="isNeedCancelBooking"
      @booking-canceled="isNeedCancelBooking = false"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>
